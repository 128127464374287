.container_que {
  padding: 10px;
}
.listCon {
  display: flex;
}
a {
  text-decoration: none;
}
.FormCOn {
  width: 98%;
  margin: auto;
}
.rightCon {
  margin-left: 5px;
}
.btn {
  width: 100%;
}
.card-title {
  color: darkred;
  text-align: justify;
}
.card-text {
  text-align: justify;
}
.questHov:hover {
  background: #f8f8f8;
}
.card {
  box-shadow: 2px 2px 15px #0000003a;
}
.Nav_back {
  width: 100%;
  background-color: #080053;
  box-shadow: 2px 2px 5px #0000003a;
  color: #fff;
  padding: 10px;
  margin-bottom: 10px;
  display: Flex;
  justify-content: space-between;
}
